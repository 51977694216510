// Imports => React
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useAutoFocus, useDocumentTitleFromPath } from '@hooks';
import { AcSetDocumentTitle, AcCapitalize } from '@utils';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => SCSS
import '@styles/index.scss';

// Imports => Config

// Imports => Constants
import { DEFAULT_ROUTE, ROUTES } from '@constants';

// Imports => Utilities
import { AcHome } from '@views';
import AcContent from '@views/ac-content/ac-content';

// Imports => Molecules
const AcHeader = loadable(() => import('@components/ac-header/ac-header'));
const AcFooter = loadable(() => import('@components/ac-footer/ac-footer'));

const App = ({ store }) => {
  const { fetchPages, all_pages } = store.pages;
  const resetFocus = useAutoFocus();

  useEffect(() => {
    fetchPages();
  }, []);

  useDocumentTitleFromPath();

  const getView = (page) => {
    return page.slug === 'home' ? (
      <AcHome store={store} />
    ) : (
      <AcContent store={store} />
    );
  };

  if (!all_pages?.length) {
    return null;
  }

  const hostname = window.location.hostname;

  const getTheme = () => {
    switch (hostname) {
      case 'vng.opencatalogi.nl':
        return 'vng-theme';
      case 'open-tilburg.accept.commonground.nu':
        return 'tilburg-theme';
      case 'open-dimpact.accept.commonground.nu':
        return 'dimpact-theme';
      case 'open-rotterdam.accept.commonground.nu':
        return 'rotterdam-theme';
      case 'open-migrato.accept.commonground.nu':
        return 'migrato-theme';
      case 'opencatalogi.nl':
        return 'opencatalogi-theme';
      case 'localhost':
        return 'opencatalogi-theme';
      default:
        return 'tilburg-theme';
    }
  };

  const setIcon = () => {
    switch (hostname) {
      case 'vng.opencatalogi.nl':
        return (
          (document.getElementById('favicon').href =
            'https://vng.nl/themes/custom/vng/favicon.ico'),
          (document.getElementById('faviconMeta').href =
            'https://vng.nl/themes/custom/vng/favicon.ico')
        );
      case 'open-tilburg.accept.commonground.nu':
        return;
      case 'open-dimpact.accept.commonground.nu':
        return (
          (document.getElementById('favicon').href =
            'https://www.dimpact.nl/wp-content/themes/dimpact-child-theme/favicon.png'),
          (document.getElementById('faviconMeta').href =
            'https://www.dimpact.nl/wp-content/themes/dimpact-child-theme/favicon.png')
        );
      case 'open-rotterdam.accept.commonground.nu':
        return (
          (document.getElementById('favicon').href =
            'https://www.rotterdam.nl/favicon.ico?v=2'),
          (document.getElementById('faviconMeta').href =
            'https://www.rotterdam.nl/favicon.ico?v=2')
        );
      case 'opencatalogi.nl':
        return (
          (document.getElementById('favicon').href =
            'https://directory.opencatalogi.nl/core/preview?fileId=309&x=2048&y=1280&a=true&etag=bab799ba75481f8107c967e49e50c008'),
          (document.getElementById('faviconMeta').href =
            'https://directory.opencatalogi.nl/core/preview?fileId=309&x=2048&y=1280&a=true&etag=bab799ba75481f8107c967e49e50c008')
        );
      case 'localhost':
        return (
          (document.getElementById('favicon').href =
            'https://www.rotterdam.nl/favicon.ico?v=2'),
          (document.getElementById('faviconMeta').href =
            'https://www.rotterdam.nl/favicon.ico?v=2')
        );
      default:
        return;
    }
  };

  useEffect(() => {
    setIcon();
  }, []);

  return (
    <div
      className={clsx(getTheme(), 'ac-app-container')}
      tabIndex='-1'
      ref={resetFocus}
    >
      <AcHeader store={store} />
      <main id='main' className='ac-app-main'>
        <Routes>
          {all_pages.map((page) => (
            <Route
              key={`route-${page.id}`}
              path={page.slug}
              element={getView(page)}
            />
          ))}
          {Object.values(ROUTES)
            .filter((route) => route.component)
            .map((route) => (
              <Route
                key={`default-route-${route.id}`}
                path={route.path}
                element={<route.component store={store} />}
              />
            ))}
          <Route
            key={`default-route-${DEFAULT_ROUTE.id}`}
            path={'*'}
            element={<AcHome store={store} />}
          />
        </Routes>
      </main>
      <AcFooter />
    </div>
  );
};

export default withStore(observer(App));
